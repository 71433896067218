<template>
    <v-row>
        <v-col cols="12">
            <v-text-field v-model="q" class="rounded-xl" :disabled="type && products.length === 0" flat solo background-color="grey lighten-3" label="Buscar" dense hide-details prepend-inner-icon="mdi-magnify" clearable></v-text-field>
            <div class="d-flex align-center mt-3">
                <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs" v-on="on" :disabled="type && products.length === 0">
                            <v-icon v-text="sortByIcon"></v-icon>
                            <span v-text="sortByText" class="ml-3"></span>
                        </v-chip>
                    </template>

                    <v-list>
                        <v-list-item v-for="(item, i) in sortItems" :key="`sort-item-${i}`" :disabled="sortBy === item.value" @click="sortBy = item.value" dense>
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-chip v-if="type && products.length > 0" class="ml-3 petu-dark-green-bg" dark v-text="getTrainingType(true)"></v-chip>
            </div>
            <div v-if="q && filteredProducts.length === 0" class="d-flex justify-center align-center mt-5" style="width: 100%;line-break:anywhere;">
                <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
                <span>No se encontraron resultados para <strong>"{{ q }}"</strong></span>
            </div>
            <div v-else-if="q" class="d-flex justify-center align-center mt-5" style="width: 100%;line-break:anywhere;">
                <span>Mostrando {{ filteredProducts.length }} {{ resultText }} para <strong>"{{ q }}"</strong></span>
            </div>
            <div v-else-if="!loading && type && products.length === 0" class="d-flex justify-center align-center mt-5" style="width: 100%;line-break:anywhere;">
                <span>No existen productos de tipo <strong>"{{ getTrainingType(true) }}"</strong></span>
            </div>
        </v-col>
        <v-col cols="6">
            <v-row justify="center" class="flex-column">
                <product-list-item v-for="(p, i) in firstHalf" :key="p.id" :product="p" :index="i"></product-list-item>
            </v-row>
        </v-col>
        <v-col cols="6">
            <v-row justify="center" class="flex-column">
                <product-list-item v-for="(p, i) in secondHalf" :key="p.id" :product="p" :index="i"></product-list-item>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { PetuProduct } from '@/classes/product'
import { TrainingType } from '@/models'
import ProductListItem from '@/components/ProductListItem'

export default {
	name: 'ProductList',
    props: { type: String },
    data: () => ({ 
        q: '',
        loading: true,
        products: [],
        sortBy: 3,
        height: false,
        sortItems: [
            { text: 'Nombre - AZ', value: 0, icon: 'mdi-sort-alphabetical-ascending' },
            { text: 'Nombre - ZA', value: 1, icon: 'mdi-sort-alphabetical-descending' },
            { text: 'Fecha Creado - ASC', value: 2, icon: 'mdi-sort-calendar-ascending' },
            { text: 'Fecha Creado - DESC', value: 3, icon: 'mdi-sort-calendar-descending' },
            { text: 'Precio - ASC', value: 4, icon: 'mdi-sort-numeric-ascending' },
            { text: 'Precio - DESC', value: 5, icon: 'mdi-sort-numeric-descending' }
        ]
    }),
    async mounted() {
        this.products = await (new PetuProduct()).getPurchasableList(this.getTrainingType()); 
        this.loading = false;
    },
    methods: {
        compare(a, b) {
            const key = [0, 1].includes(this.sortBy) ? 'name' : (
               [2, 3].includes(this.sortBy) ? 'createdAt' : 'price'
            )
            const isASC = [0, 2, 4].includes(this.sortBy)

            if (isASC) {
                return a[key] < b[key] ? - 1 : Number(a[key] > b[key])
            } else {
                return a[key] > b[key] ? - 1 : Number(a[key] < b[key])
            }
        },
        getTrainingType(text = false) {
            switch (this.type) {
                case 'online'       : return TrainingType.ONLINE;
                case 'one-on-one'   : return text ? 'One on One' : TrainingType.ONE_ON_ONE;
                case 'presencial'   : return TrainingType.PRESENCIAL;
                case 'outfit'       : return text ? 'Petu Outfit' : TrainingType.NA
                default: return '';
            }
        },
    },
    computed: {
        filteredProducts() {
            return (this.q
            ? this.products.filter((p) => p.name.toLowerCase().includes(this.q.toLowerCase()))
            : this.products).sort(this.compare)
        },
        sortByText() {
            return this.sortItems.find((i) => i.value === this.sortBy).text
        },
        sortByIcon() {
            return this.sortItems.find((i) => i.value === this.sortBy).icon
        },
        resultText() {
            return this.filteredProducts.length === 1 ? 'resultado' : 'resultados'
        },
        firstHalf() {
            return this.filteredProducts.filter((p, i) => i % 2 === 0);
        },
        secondHalf() {
            return this.filteredProducts.filter((p, i) => i % 2 === 1);
        }
    },
    components: { ProductListItem }
}
</script>
