<template>
    <v-col cols="12" class="pb-0">
        <v-card flat class="rounded-xl mb-2"  :color="color" :to="`/producto/${product.slug}`" style="overflow: hidden">
            <v-card-title class="pb-2" style="word-break: break-word;">{{ product.name }}</v-card-title>
            <v-card-subtitle class="pb-7">{{ price }}</v-card-subtitle>
            <v-card-text>
                <v-img class="rounded-xl" contain width="100%" :src="imageSrc"></v-img>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-card-actions class="justify-end" style="position:absolute; bottom: 2.5px;right:2.5px">
                <v-btn @click.prevent="addToCartHandler" :loading="loading" small class="petu-pink-bg rounded-xl white--text"><v-icon>mdi-plus</v-icon></v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>
<script>
import { mapActions } from 'vuex'
import { PetuProduct } from '@/classes/product'
import { _st } from '@/softech'

export default {
	name: 'ProductListItem',
    props: { index: { type: Number }, product: { type: Object }},
    data: () => ({ 
        loading: false, 
        imageSrc: '' 
    }),
    methods: {
        ...mapActions({ 
            addToCart : 'cartInfo/addToCart' 
        }),
        async addToCartHandler() {
            this.loading = true
            await this.addToCart({ productId: this.product.id, qty: 1 }).then(() => {
                this.$root.$emit('show-cart')
            }).catch((err) => {
                console.error(err)
            }).finally(() => this.loading = false)
        },
    },
    computed: {
        price() { 
            return _st.toMoney(this.product.price)
        },
        padding() {
            return this.index % 2 === 0 ? 'pr-1' : 'pl-1';
        },
        color() {
            switch (this.index % 4) {
                case 0: return 'blue lighten-5'
                case 1: return 'red lighten-5'
                case 2: return 'green lighten-5'
                case 3: return 'yellow lighten-5'
            }
        },
    },
    async mounted() {
        let p = new PetuProduct(this.product);
        this.imageSrc = await p.getProductImage();
        console.log( this.imageSrc );
        console.log(p);
    }
}
</script>
